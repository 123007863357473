(function () {
  var moreH = $('#moreH').height(),
      cards = $('.card-txt'),
      ww = $(window).width();
  // alert(ww);
  if (ww > 768){
    cards.css({
      'minHeight': moreH+'px'
    });
  }
})();
(function () {
  var $banner = $('#img-banner');
  var $width = $banner.width();
  var $windowH = $(window).width();
  if ($windowH < 768){
    var $heightImg = $width * .35;
  }else {
    var $heightImg = $width * .25;
  }
  // alert("hola");
  $banner.css({
    'overflow': 'hidden',
    'height': $heightImg
  })
})();

function justNumbers(e) {
  var keynum = window.event ? window.event.keyCode : e.which;
  if ( keynum == 8 ) return true;
  return /\d/.test(String.fromCharCode(keynum));
}

(function () {
  // var formContact = $('#form-contact');
  // formContact.on('submit', function (e) {
  //   e.preventDefault();
  //   var data=$(this).serializeArray(),
  //       url='http://urbanaavanzada.com.mx/tmp/quotation.php';
  //   $.ajax({
  //     url: url,
  //     type:'post',
  //     dataType: 'json',
  //     data: data
  //   })
  //     .done(function(){
  //       alert('correcto')
  //     })
  //     .fail(function(){
  //       alert('fallo')
  //     })
  // })

})();

(function () {
  // #form-recruitment, #form-quotation, #form-recruitment
  var recruitment = $('#form-recruitment'),
      quotation = $('#form-quotation'),
      contact = $('#form-contact'),
      select = $('#select');
  select.on('change', function (e) {
    // alert(select.val())
    if (select.val()==1){
      recruitment.fadeOut();
      quotation.fadeOut();
      contact.fadeIn();
    }else if (select.val()==2){
      recruitment.fadeOut();
      quotation.fadeIn();
      contact.fadeOut();
    }else if (select.val()==3){
      recruitment.fadeIn();
      quotation.fadeOut();
      contact.fadeOut();
    }
  });
})();